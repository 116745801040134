import autoBind from 'auto-bind';

export class ENavigationSearchButtonEvents {
  constructor(component) {
    autoBind(this);
    this.toggle = component.toggle.bind(component);
  }

  onClick() {
    this.toggle();
  }
}

export default ENavigationSearchButtonEvents;
