import autoBind from 'auto-bind';

export class ENavigationProfileMenuEvents {
  constructor(component) {
    autoBind(this);
    this.dispatchEvent = component.dispatchEvent.bind(component);

    this.state = component.state;
    this.refs = component.refs;
  }

  onItemClick(event) {
    const item = {
      id: event.currentTarget.getAttribute('menu-item-id'),
      type: event.currentTarget.getAttribute('menu-item-type'),
      url: event.currentTarget.getAttribute('href'),
      target: event.currentTarget.getAttribute('target')
    };

    this.dispatchEvent(new CustomEvent('profileMenu.itemClick', { bubbles: true, detail: item }));
  }

  async onShareAccountButtonClick() {

    const sessionId = window.e.config?.sessionId;

    if (sessionId === undefined) { return; }

    this.refs.shareAccountAccessIFrame.src = `/bootstrap.php?session_id=${sessionId}&r=customerSession/LoginPermit`;
    this.refs.shareAccountAccessDialog.open();
  }

  async onShareAccountSettingsChange(event) {
    const serviceUrl = window.e.config?.services?.userManagement?.url;

    if (event.origin !== serviceUrl || event.data !== 'share-account-changed') { return; }

    window.e.utils.openNotification({
      type: 'success',
      content: {
        type: 'html',
        template: '<e-translation key="components.navigationSuiteProfile.shareAccountDialogSuccess"></e-translation>'
      },
      closable: true
    });
  }
}
