import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import State from './state';
import Template from './template';

class ENavigationProfileDate extends HTMLCustomElement {
  init() {
    this._state = new State(this._render.bind(this));
    this._template = new Template(this);
    this._updateDate = this._updateDate.bind(this);
    this._interval = null;
  }

  connectedCallback() {
    this._updateDate();
    this._interval = setInterval(this._updateDate, 5000);
  }

  disconnectedCallback() {
    clearInterval(this._interval);
    this._render();
  }

  static get observedAttributes() {
    return ['timezone-offset'];
  }

  set timezoneOffset(value) {
    this._state.setState({ timezoneOffset: parseFloat(value) });
    this._updateDate();
  }

  get timezoneOffset() {
    return this._state.state.timezoneOffset;
  }

  _updateDate() {
    const currentDate = new Date();
    const offsetInSeconds = this._state.state.timezoneOffset;
    const hours = Math.floor((offsetInSeconds / 60) / 60);
    const minutes = Math.abs(Math.floor(offsetInSeconds / 60) % 60);

    currentDate.setHours(currentDate.getHours() + hours);
    currentDate.setMinutes(currentDate.getMinutes() + minutes);

    this._state.setState({ datetime: currentDate });
  }

  _render() {
    this._template.render(this._state.state);
  }
}

export default ENavigationProfileDate;
