import translator from '../../../utils/translator/index.js';

export const config = {
  'contact': {
    placeholder: translator.translate('components.navigationSuiteSearch.labels.contact'),
    optionLabel: translator.translate('components.navigationSuiteSearch.placeholders.contact'),
    getUrl: ({ needle, sessionId }) => {
      return `userprofiles.php?session_id=${sessionId}&action=searchnlist&search=${needle}&profile=&elements=&qs=1`;
    }
  },
  'contactByFirstName': {
    optionLabel: translator.translate('components.navigationSuiteSearch.labels.contactByFirstName'),
    placeholder: translator.translate('components.navigationSuiteSearch.placeholders.contactByFirstName'),
    getUrl: ({ needle, sessionId }) => {
      return `userprofiles.php?session_id=${sessionId}&action=searchnlist&search=${needle}&profile=&elements=1&qs=1.1`;
    }
  },
  'contactByLastName': {
    optionLabel: translator.translate('components.navigationSuiteSearch.labels.contactByLastName'),
    placeholder: translator.translate('components.navigationSuiteSearch.placeholders.contactByLastName'),
    getUrl: ({ needle, sessionId }) => {
      return `userprofiles.php?session_id=${sessionId}&action=searchnlist&search=${needle}&profile=&elements=2&qs=1.2`;
    }
  },
  'contactByEmail': {
    optionLabel: translator.translate('components.navigationSuiteSearch.labels.contactByEmail'),
    placeholder: translator.translate('components.navigationSuiteSearch.placeholders.contactByEmail'),
    getUrl: ({ needle, sessionId }) => {
      return `userprofiles.php?session_id=${sessionId}&action=searchnlist&search=${needle}&profile=&elements=3&qs=1.3`;
    }
  },
  'emailCampaigns': {
    optionLabel: translator.translate('components.navigationSuiteSearch.labels.emailCampaigns'),
    placeholder: translator.translate('components.navigationSuiteSearch.placeholders.emailCampaigns'),
    getUrl: ({ needle, sessionId }) => `campaignmanager.php?session_id=${sessionId}&action=list&search=${needle}&qs=2`
  },
  'registrationForms': {
    optionLabel: translator.translate('components.navigationSuiteSearch.labels.registrationForms'),
    placeholder: translator.translate('components.navigationSuiteSearch.placeholders.registrationForms'),
    getUrl: ({ needle, sessionId }) => `regmanager.php?session_id=${sessionId}&action=list&search=${needle}&qs=3`
  },
  'segments': {
    optionLabel: translator.translate('components.navigationSuiteSearch.labels.segments'),
    placeholder: translator.translate('components.navigationSuiteSearch.placeholders.segments'),
    getUrl: ({ needle, sessionId }) => `querymanager.php?session_id=${sessionId}&action=list&search=${needle}&qs=4`
  },
  'lists': {
    optionLabel: translator.translate('components.navigationSuiteSearch.labels.lists'),
    placeholder: translator.translate('components.navigationSuiteSearch.placeholders.lists'),
    getUrl: ({ needle, sessionId }) => `querymanager.php?session_id=${sessionId}&action=overview&search=${needle}&qs=5`
  },
  'bounces': {
    optionLabel: translator.translate('components.navigationSuiteSearch.labels.bounces'),
    placeholder: translator.translate('components.navigationSuiteSearch.placeholders.bounces'),
    getUrl: ({ needle, sessionId }) => {
      return `adminmanager.php?session_id=${sessionId}&action=invmails&quicksearch=${needle}&qs=6`;
    }
  },
  'analyses': {
    optionLabel: translator.translate('components.navigationSuiteSearch.labels.analyses'),
    placeholder: translator.translate('components.navigationSuiteSearch.placeholders.analyses'),
    getUrl: ({ needle, sessionId }) => `repmanager.php?session_id=${sessionId}&action=overview&search=${needle}&qs=7`
  }
};

export default config;
