import { HTMLCustomElement, convertAttributeToBoolean, convertAttributeToArray } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import { ENavigationState } from './state';
import { ENavigationEvents } from './events';
import { ENavigationTemplate } from './template';

export class ENavigation extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = {};
    this.state = new ENavigationState(this);
    this.events = new ENavigationEvents(this);
    this.template = new ENavigationTemplate(this);
  }

  connectedCallback() {
    this.currentUrl = this.state.currentUrl || window.location.href;
    this.render();
  }

  // Attributes
  static get observedAttributes() {
    return ['menu', 'sticky', 'open-delay', 'current-url'];
  }

  get currentUrl() {
    return this.state.currentUrl;
  }

  set currentUrl(value) {
    this.state.currentUrl = value || window.location.href;
  }

  set menu(menu) {
    this.state.menu = convertAttributeToArray(menu);
  }

  set sticky(value) {
    this.state.sticky = convertAttributeToBoolean(value);
  }

  set openDelay(value) {
    this.state.openDelay = parseInt(value);
  }

  // Rendering
  render() {
    render(this, this.template.createComponent());
  }
}

export default ENavigation;
