import { html } from 'uhtml';

export class ENavigationProfileMenuTemplate {
  constructor(component) {
    this.state = component.state;
    this.events = component.events;
    this.refs = component.refs;
  }

  createElement() {
    return html`
      ${this.createHeader()}
      ${this.createMenu()}
      ${this.createAccountInfo()}
    `;
  }

  createHeader() {
    return html`
      <div class="e-navigation-profile-menu__header">
        <div class="e-navigation-profile-menu__logo">
          ${this.state.customerName.slice(0, 2)}<br>${this.state.customerName.slice(-2)}
        </div>
        <div class="e-margin-left-s">
          <div>${this.state.customerName}</div>
          <div>${this.state.adminName}</div>
        </div>
      </div>
    `;
  }

  createMenu() {
    return html`
      <div class="e-navigation-profile-menu__menu">
        ${this.state.menuByGroups.map(group => this.createMenuGroup(group))}
      </div>
    `;
  }

  createMenuGroup({ name, items }) {
    return html`
      ${this.createGroupTitle(name)}
      ${items.map((item) => this.createMenuItem(item))}
    `;
  }

  createGroupTitle(name) {
    if (!name) { return null; }

    return html`<div class="e-navigation__submenu_group_title">${name}</div>`;
  }

  createMenuItem(item) {
    if (item.id === 'profile-share_account') {
      return this.createShareAccountMenuItem(item);
    }

    if (item.id === 'profile-theme_switcher') {
      return this.createThemeSwitcher();
    }

    return this.createLinkMenuItem(item);
  }

  createLinkMenuItem(item) {
    return html`
      <a
        role="menuitem"
        class="e-navigation__submenu_action"
        id=${item.element_id}
        menu-item-id=${item.id}
        menu-item-type=${item.type}
        tracking-id=${item.id}
        tracking-action="Clicked_Submenu"
        href=${item.url === '#' ? null : item.url}
        target=${item.target}
        @click=${this.events.onItemClick}
      >
        ${this.createIcon(item)}
        ${item.text}
      </a>
  `;
  }

  createShareAccountMenuItem(item) {
    return html`
      <button
        role="menuitem"
        aria-haspopup="dialog"
        type="button"
        class="e-navigation__submenu_action"
        id=${item.element_id}
        menu-item-id=${item.id}
        menu-item-type=${item.type}
        tracking-id=${item.id}
        tracking-action="Clicked_Submenu"
        @click=${this.events.onItemClick}
        @click=${this.events.onShareAccountButtonClick}
      >
        ${this.createIcon(item)}
        ${item.text}
      </button>
      ${this.createShareAccountDialog(item.text)}
    `;
  }

  createIcon(item) {
    const iconName = item.icon_id ? item.icon_id : `e-nav-${item.id.replace(/^profile-/, '').replace(/_/g, '-')}`;

    return html`
      <e-navigation-submenu-icon>
        <e-icon icon=${iconName} color="inherit"></e-icon>
      </e-navigation-submenu-icon>
    `;
  }

  createShareAccountDialog(headline) {
    return html`
      <e-dialog
        class="e-dialog-iframe"
        id="share-account-dialog"
        headline="${headline}"
        width="650px"
        height="600px"
        no-keys
        ref=${node => this.refs.shareAccountAccessDialog = node}
      >
        <e-iframe-loader style="width: 100%; height: 100%; display: block;">
          <iframe
            id="share-account-dialog-iframe"
            width="100%"
            height="100%"
            ref=${node => this.refs.shareAccountAccessIFrame = node}
          ></iframe>
        </e-iframe-loader>
        <div class="e-dialog__footer">
          <div class="e-grid e-grid-small">
            <div class="e-cell e-cell-small">
              <button class="e-btn e-btn-primary" data-action="close">
                <e-translation key="components.navigationSuiteProfile.shareAccountDialogDone"></e-translation>
              </button>
            </div>
          </div>
        </div>
      </e-dialog>
    `;
  }

  createThemeSwitcher() {
    return html`
      <e-theme-switcher>
        <button
          role="menuitem"
          aria-haspopup="dialog"
          type="button"
          class="e-navigation__submenu_action"
          @click=${this.events.onItemClick}
        >
          <e-navigation-submenu-icon>
            <e-icon icon="e-style" color="inherit"></e-icon>
          </e-navigation-submenu-icon>
          <e-translation key="components.navigationSuiteProfile.themeSwitcher"></e-translation>
        </button>
      </e-theme-switcher>
    `;
  }

  createAccountInfo() {
    /* eslint-disable max-len */
    return html`
      <div class="e-navigation-profile-menu__account_info">
        <small class="text-color-faint">
          <e-translation key="components.navigationSuiteProfile.serverTimeLabel"></e-translation>
        </small>
        <div class="e-margin-bottom-xs">
          <e-navigation-profile-date timezone-offset="${this.state.timezoneOffset}"></e-navigation-profile-date>
          <e-navigation-suite-profile-timezone>(${this.state.timezone})</e-navigation-suite-profile-timezone>
        </div>

        <small class="text-color-faint">
          <e-translation key="components.navigationSuiteProfile.accountIDLabel"></e-translation>
        </small>
        <e-navigation-suite-profile-account-id>${this.state.customerId}</e-navigation-suite-profile-account-id>
      </div class="e-navigation-profile-menu__account_info">
    `;
    /* eslint-enable max-len */
  }
}
