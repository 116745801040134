import { html } from 'uhtml';
import translator from '../../../utils/translator';

export class ENavigationProfileButtonTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  createComponent() {
    return html`
      ${this.createButton()}
      ${this.createSubmenu()}
    `;
  }

  createButton() {
    // eslint-disable-next-line max-len
    const ariaLabel = `${translator.translate('components.navigationProfileButton.tooltip')}, ${this.state.customerName}, ${this.state.adminName}`;

    return html`
    <e-tooltip content="${translator.translate('components.navigationProfileButton.tooltip')}">
        <button
          aria-haspopup="true"
          class="e-shellbar__action"
          type="button"
          tracking-id="profile"
          tracking-action="clickedShellbar"
          @click=${this.events.onClick}
          ref=${node => this.refs.button = node}
          aria-label="${ariaLabel}"
          aria-expanded="${this.state.menuIsOpen}"
        >
          <div class="e-navigation-profile-button__logo" aria-hidden="true">
            ${this.state.customerName.slice(0, 2)}<br>${this.state.customerName.slice(-2)}
          </div>
        </button>
      </e-tooltip>
    `;
  }

  createSubmenu() {
    return html`
      <div class="e-shellbar__submenu" role="menu" ref=${node => this.refs.submenu = node}>
        <div class="e-shellbar__submenu__scrollarea">
          <e-navigation-profile-menu
            .menu=${this.state.menu}
            .sessionId=${this.state.sessionId}
            .customerName=${this.state.customerName}
            .customerId=${this.state.customerId}
            .adminName=${this.state.adminName}
            .timezone=${this.state.timezone}
            .timezoneOffset=${this.state.timezoneOffset}
          ></e-navigation-profile-menu>
        </div>
      </div>
    `;
  }
}

export default ENavigationProfileButtonTemplate;
