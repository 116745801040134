import autoBind from 'auto-bind';

export class ENavigationProfileButtonEvents {
  constructor(component) {
    autoBind(this);
    this.close = component.close.bind(component);
    this.toggle = component.toggle.bind(component);

    this.refs = component.refs;
  }

  onClick() {
    this.toggle();
  }

  onSubmenuItemClick() {
    this.close();
  }

  onAfterOpen() {
    this.refs.submenu.querySelector('a').focus();
  }
}

export default ENavigationProfileButtonEvents;
