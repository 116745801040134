import State from '../../../utils/state';

class ENavigationProfileDateState extends State {
  get defaultState() {
    return {
      datetime: new Date(),
      timezoneOffset: 0,

      get datetimeString() {
        return this.datetime.toISOString().replace('Z', '');
      }
    };
  }
}

export default ENavigationProfileDateState;
