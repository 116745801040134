import { html } from 'uhtml';
import translator from '../../../utils/translator';

export class ENavigationSearchButtonTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    return html`
      ${this.createButton()}
      ${this.createSubmenu()}
    `;
  }

  createButton() {
    return html`
      <e-tooltip content="${translator.translate('components.navigationSearchButton.tooltip')}">
        <button
          aria-haspopup="dialog"
          class="e-shellbar__action"
          type="button"
          tracking-id="search"
          tracking-action="clickedShellbar"
          @click=${this.events.onClick}
          ref=${node => this.refs.button = node}
          aria-label="${translator.translate('components.navigationSearchButton.tooltip')}"
        >
          <e-icon class="e-shellbar__action_icon" icon="search" color="inherit"></e-icon>
        </button>
      </e-tooltip>
    `;
  }

  createSubmenu() {
    return html`
      <div class="e-shellbar__submenu" role="dialog" ref=${node => this.refs.submenu = node}>
        <e-navigation-search-form
          session-id=${this.state.sessionId}
          charset=${this.state.charset}
          ?splitted-quick-search=${this.state.splittedQuickSearch}
        ></e-navigation-search-form>
      </div>
    `;
  }
}

export default ENavigationSearchButtonTemplate;
