import { html } from 'uhtml';
import classNames from 'clsx';
import translator from '../../../utils/translator';


const iconMap = {
  addons: 'e-nav-addons-2x',
  analytics_new_main: 'e-nav-analytics-2x',
  automation_new_main: 'e-nav-automation-2x',
  channels_new_main: 'e-nav-channels-2x',
  contacts_new_main: 'e-nav-contacts-2x',
  content_new_main: 'e-nav-content-2x',
  featured_new_main: 'e-nav-featured-2x',
  management_new_main: 'e-nav-management-2x',
  show_more: 'e-nav-more-2x'
};

export class ENavigationTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  createComponent() {
    const panelClassList = classNames('e-navigation__panel', {
      'e-navigation__panel-open': this.state.isMenuOpen
    });

    const menuClassList = classNames('e-navigation__menu', {
      'e-navigation__menu-open': !!this.state.activeMenuItem
    });

    return html`
      <div
        class=${panelClassList}
        @keydown=${this.events.onMenuEscape}
        @focusin=${this.events.onMainMenuFocusWithin}
        @focusout=${this.events.onMainMenuFocusOutside}
        @mouseenter=${this.events.onMainMenuMouseEnter}
        @mouseleave=${this.events.onMainMenuMouseLeave}
        ref=${node => this.refs.mainMenu = node}
      >
        <nav class=${menuClassList} aria-label="${translator.translate('components.navigation.title')}">
          <ul class="e-navigation__menu_list">
            ${this.state.menu.map(menuItem => this.createMainMenuItem(menuItem))}
          </ul>
        </nav>
      </div>
    `;
  }

  createMainMenuItem(menuItem) {
    const isCurrentMenuItem = menuItem.submenu.includes(this.state.currentSubmenuItem);
    const isActiveMenuItem = this.state.activeMenuItem?.id === menuItem.id;

    const menuItemClassName = [
      'e-navigation__action',
      ...isActiveMenuItem ? ['e-navigation__action-active'] : [],
      ...isCurrentMenuItem ? ['e-navigation__action-current'] : []
    ].join(' ');

    return html`
      <li class="e-navigation__menu_list_item">
        <button
          class="${menuItemClassName}"
          type="button"
          menu-item-id=${menuItem.id}
          tracking-action="Clicked_MainMenu"
          tracking-id=${menuItem.id}
          aria-label="${menuItem.text}"
          aria-haspopup="true"
          aria-expanded=${isActiveMenuItem}
          @click=${this.events.onMainMenuItemClick}
        >
          <e-icon class="e-navigation__action_icon" icon="${iconMap[menuItem.id]}" color="inherit"></e-icon>
          <span class="e-navigation__action_text">${menuItem.text}</span>
        </button>
        ${this.createSubmenu(menuItem, isActiveMenuItem)}
      </li>
    `;
  }

  createSubmenu(menuItem, isOpen) {
    const classList = [
      'e-navigation__submenu',
      ...isOpen ? ['e-navigation__submenu-open'] : []
    ].join(' ');

    return html`
      <div class=${classList} role="menu" aria-label="${menuItem.text}">
        <h2 class="e-navigation__submenu_title">${menuItem.text}</h2>
        ${this.state.getSubmenuByGroups(menuItem).map(group => this.createSubmenuGroup(group))}
      </div>
    `;
  }

  createSubmenuGroup(group) {
    return html`
      ${this.createSubmenuTitle(group.name)}
      <ul class="e-navigation__submenu_list" role="none">
        ${group.items.map(submenuItem => this.createSubmenuItem(submenuItem))}
      </ul>
    `;
  }

  createSubmenuTitle(title) {
    if (!title) { return; }

    return html`
      <h3 class="e-navigation__submenu_group_title">${title}</h3>
    `;
  }

  createSubmenuItem(submenuItem) {
    const isCurrentSubmenuItem = this.state.currentSubmenuItem === submenuItem;

    const submenuItemClassName = [
      'e-navigation__submenu_action',
      ...isCurrentSubmenuItem ? ['e-navigation__submenu_action-current'] : []
    ].join(' ');

    const itemMarkupLink = html`
      <a
        role="menuitem"
        class=${submenuItemClassName}
        href="${submenuItem.url}"
        menu-item-id=${submenuItem.id}
        tracking-action="Clicked_Submenu"
        tracking-id=${submenuItem.id}
        aria-label="${submenuItem.text}"
        @click=${this.events.onMenuItemClick}
      >${submenuItem.text}</a>`;

    const itemMarkupButton = html`
      <button
        role="menuitem"
        type="button"
        class=${submenuItemClassName}
        menu-item-id=${submenuItem.id}
        tracking-action="Clicked_Submenu"
        tracking-id=${submenuItem.id}
        aria-label="${submenuItem.text}"
        @click=${this.events.onMenuItemClick}
      >${submenuItem.text}</button>`;

    return html`
      <li class="e-navigation__submenu_list_item" role="none">
        ${submenuItem.url !== '' ? itemMarkupLink : itemMarkupButton}
      </li>
    `;
  }
}

export default ENavigationTemplate;
