import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import popupUtility from '../../../utils/popup';

import { ENavigationSearchButtonState } from './state';
import { ENavigationSearchButtonEvents } from './events';
import { ENavigationSearchButtonTemplate } from './template';

export class ENavigationSearchButton extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = {};
    this.state = new ENavigationSearchButtonState(this);
    this.events = new ENavigationSearchButtonEvents(this);
    this.template = new ENavigationSearchButtonTemplate(this);
  }

  connectedCallback() {
    this.dispatchEvent(new CustomEvent('connect', { bubbles: true }));
    this.render();

    const searchInput = this.refs.submenu.querySelector('#e-navigation-search-form-input');

    this.popup = popupUtility.createPopup(this.refs.button, this.refs.submenu, {
      elementToFocusOnOpen: searchInput
    });
  }

  // Attributes
  static get observedAttributes() {
    return ['charset', 'session-id', 'splitted-quick-search'];
  }

  get charset() {
    return this.state.charset;
  }

  set charset(value) {
    this.state.charset = value;
  }

  get sessionId() {
    return this.state.sessionId;
  }

  set sessionId(value) {
    this.state.sessionId = value;
  }

  get splittedQuickSearch() {
    return this.state.splittedQuickSearch;
  }

  set splittedQuickSearch(value) {
    this.state.splittedQuickSearch = super._convertAttributeToBoolean(value);
  }

  // Actions
  open() {
    this.popup.open();
  }

  close() {
    this.popup.close();
  }

  toggle() {
    this.popup.toggle();
  }

  // Rendering
  render() {
    render(this, this.template.createElement());
  }
}

export default ENavigationSearchButton;
