export class ENavigationSearchButtonState {
  constructor(component) {
    this.render = component.render.bind(component);

    this._sessionId = null;
    this._charset = 'utf-8';
    this._splittedQuickSearch = false;
  }

  get sessionId() {
    return this._sessionId;
  }

  set sessionId(value) {
    this._sessionId = value;
    this.render();
  }

  get charset() {
    return this._charset;
  }

  set charset(value) {
    this._charset = value;
    this.render();
  }

  get splittedQuickSearch() {
    return this._splittedQuickSearch;
  }

  set splittedQuickSearch(value) {
    this._splittedQuickSearch = value;
    this.render();
  }
}

export default ENavigationSearchButtonState;
