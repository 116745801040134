import { html } from 'uhtml';
import { config } from './config';

export class ENavigationSearchFormTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  createSearchForm() {
    return html`
      <h2><e-translation key="components.navigationSuiteSearch.header"></e-translation></h2>
      ${this.createSearchSelect()}
      ${this.createSearchInput()}
      ${this.createSearchButton()}
    `;
  }

  createSearchSelect() {
    return html`
      <div class="e-field">
        <label class="e-field__label for="e-navigation-search-form-select">
          <e-translation key="components.navigationSuiteSearch.searchScopeLabel"></e-translation>
        </label>
        <e-select
          id="e-navigation-search-form-select"
          local
          @change=${this.events.onOptionChange}
          @open=${this.events.onStick}
          @close=${this.events.onUnStick}
        >
          ${this.createOptions()}
        </e-select>
      </div>
    `;
  }

  createOptions() {
    const commonOptions = ['emailCampaigns', 'registrationForms', 'segments', 'lists', 'bounces', 'analyses'];
    const options = this.state.splittedQuickSearch ?
      ['contactByFirstName', 'contactByLastName', 'contactByEmail', ...commonOptions] :
      ['contact', ...commonOptions];
    return options.map((option) => this.createOption(option));
  }

  createOption(value) {
    const isSelected = value === this.state.searchOption;
    return html`
      <e-select-option value="${value}" ?selected=${isSelected}>
        ${config[value].optionLabel}
      </e-select-option>
    `;
  }

  createSearchInput() {
    return html`
      <div class="e-field">
        <label class="e-field__label" for="e-navigation-search-form-input">
          <e-translation key="components.navigationSuiteSearch.searchKeywordLabel"></e-translation>
        </label>
        <input
          type="search"
          id="e-navigation-search-form-input"
          class="e-input e-input-search"
          value="${this.state.searchKeyword}"
          placeholder=${this.state.placeholder}
          @focus=${this.events.onStick}
          @blur=${this.events.onUnStick}
          @keyup=${this.events.onEnterPress}
          @input=${this.events.onInputChange}
        >
      </div>
    `;
  }

  createSearchButton() {
    const classes = `e-btn e-btn-primary ${!this.state.searchKeyword ? 'e-btn-disabled' : ''}`;

    return html`
      <a
        class=${classes}
        href=${this.state.url}
        ref=${node => this.refs.searchButton = node}
        aria-disabled="${!this.state.searchKeyword}"
        @click=${this.events.onSearchButtonClick}
      >
        <e-translation key="components.navigationSuiteSearch.actionButton"></e-translation>
      </a>
    `;
  }
}

export default ENavigationSearchFormTemplate;
