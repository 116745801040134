import autoBind from 'auto-bind';

export class ENavigationSearchFormEvents {
  constructor(component) {
    autoBind(this);
    this.dispatchEvent = component.dispatchEvent.bind(component);

    this.refs = component.refs;
    this.state = component.state;
  }

  onInputChange(event) {
    this.state.searchKeyword = event.target.value;
  }

  onOptionChange(event) {
    this.state.searchOption = event.target.value;

    window.e.utils.v2.trace.trackEvent({
      category: 'Navigation',
      action: 'Clicked_CategorySelect_ShellbarSearch',
      label: this.state.searchOption
    });
  }

  onEnterPress(event) {
    if (event.code !== 'Enter' || !this.state.searchKeyword) { return; }

    this.refs.searchButton.click();
  }

  onSearchButtonClick() {
    if (!this.state.searchKeyword) { return; }

    window.e.utils.v2.trace.trackEvent({
      category: 'Navigation',
      action: 'Clicked_Button_ShellbarSearch',
      label: this.state.searchOption
    });
  }

  onStick() {
    this.dispatchEvent(new CustomEvent('stick', { bubbles: true }));
  }

  onUnStick() {
    this.dispatchEvent(new CustomEvent('unstick', { bubbles: true }));
  }
}

export default ENavigationSearchFormEvents;
