import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import popupUtility from '../../../utils/popup';

import { ENavigationProfileButtonState } from './state';
import { ENavigationProfileButtonEvents } from './events';
import { ENavigationProfileButtonTemplate } from './template';

export class ENavigationProfileButton extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = {};
    this.state = new ENavigationProfileButtonState(this);
    this.events = new ENavigationProfileButtonEvents(this);
    this.template = new ENavigationProfileButtonTemplate(this);
  }

  connectedCallback() {
    this.classList.add('e-navigation-profile-button');

    this.dispatchEvent(new CustomEvent('connect', { bubbles: true }));
    this.render();

    this.popup = popupUtility.createPopup(this.refs.button, this.refs.submenu, {
      onAfterOpen: this.events.onAfterOpen
    });

    this.refs.submenu.addEventListener('profileMenu.itemClick', this.events.onSubmenuItemClick);
  }

  // Attributes
  static get observedAttributes() {
    return [
      'session-id', 'menu', 'customer-name', 'customer-id', 'admin-name', 'timezone',
      'timezone-offset'
    ];
  }

  set sessionId(value) {
    this.state.sessionId = value;
  }

  set menu(value) {
    const jsonValue = typeof value === 'string' ? value : JSON.stringify(value);
    this.state.menu = JSON.parse(jsonValue);
  }

  set customerName(value) {
    this.state.customerName = value;
  }

  set customerId(value) {
    this.state.customerId = value;
  }

  set adminName(value) {
    this.state.adminName = value;
  }

  set timezone(value) {
    this.state.timezone = value;
  }

  set timezoneOffset(value) {
    this.state.timezoneOffset = value;
  }

  // Actions
  open() {
    this.state.menuIsOpen = true;
    this.popup.open();
  }

  close() {
    this.state.menuIsOpen = false;
    this.popup.close();
  }

  toggle() {
    this.state.menuIsOpen = !this.state.menuIsOpen;
    this.popup.toggle();
  }

  // Rendering
  render() {
    render(this, this.template.createComponent());
  }
}

export default ENavigationProfileButton;
