import navigation from './components/navigation/navigation';
import navigationSearchButton from './components/navigation/navigation-search-button';
import navigationSearchForm from './components/navigation/navigation-search-form';
import navigationProfileButton from './components/navigation/navigation-profile-button';
import navigationProfileMenu from './components/navigation/navigation-profile-menu';
import navigationProfileDate from './components/navigation/navigation-profile-date';


window.customElements.define('e-navigation', navigation);
window.customElements.define('e-navigation-profile-date', navigationProfileDate);
window.customElements.define('e-navigation-search-button', navigationSearchButton);
window.customElements.define('e-navigation-search-form', navigationSearchForm);
window.customElements.define('e-navigation-profile-button', navigationProfileButton);
window.customElements.define('e-navigation-profile-menu', navigationProfileMenu);
