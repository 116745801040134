export class ENavigationProfileMenuState {
  constructor(component) {
    this.render = component.render.bind(component);

    this._menu = [];
    this._sessionId = '';
    this._customerName = '';
    this._customerId = '';
    this._adminName = '';
    this._timezone = '';
    this._timezoneOffset = 0;
  }

  get menu() {
    return this._menu;
  }

  set menu(value) {
    this._menu = value;
    this.render();
  }

  get menuByGroups() {
    const groups = [...new Set(this._menu.map(item => item.group))].map(group => ({ name: group, items: [] }));

    for (const item of this._menu) {
      groups.find(group => group.name === item.group).items.push(item);
    }

    return groups;
  }


  get sessionId() {
    return this._sessionId;
  }

  set sessionId(value) {
    this._sessionId = value;
    this.render();
  }

  get customerName() {
    return this._customerName;
  }

  set customerName(value) {
    this._customerName = value;
    this.render();
  }

  get customerId() {
    return this._customerId;
  }

  set customerId(value) {
    this._customerId = value;
    this.render();
  }

  get adminName() {
    return this._adminName;
  }

  set adminName(value) {
    this._adminName = value;
    this.render();
  }

  get timezone() {
    return this._timezone;
  }

  set timezone(value) {
    this._timezone = value;
    this.render();
  }

  get timezoneOffset() {
    return this._timezoneOffset;
  }

  set timezoneOffset(value) {
    this._timezoneOffset = value;
    this.render();
  }
}

export default ENavigationProfileMenuState;
