export class ENavigationState {
  constructor(component) {
    this.render = component.render.bind(component);

    this._menu = [];
    this._sticky = false;
    this._openDelay = 300;
    this._currentUrl = '';
    this._isMenuOpen = false;
    this._activeMenuItem = null;
  }

  get menu() {
    return this._menu;
  }

  set menu(menu) {
    this._menu = menu;
    this.render();
  }

  get sticky() {
    return this._sticky;
  }

  set sticky(value) {
    this._sticky = value;
    this.render();
  }

  get openDelay() {
    return this._openDelay;
  }

  set openDelay(value) {
    this._openDelay = value;
    this.render();
  }

  get currentUrl() {
    return this._currentUrl;
  }

  set currentUrl(value) {
    this._currentUrl = value;
    this.render();
  }

  get isMenuOpen() {
    return this._sticky || this._isMenuOpen;
  }

  set isMenuOpen(value) {
    this._isMenuOpen = value;
    this.render();
  }

  get activeMenuItem() {
    return this._activeMenuItem;
  }

  set activeMenuItem(activeMenuItem) {
    this._activeMenuItem = activeMenuItem;
    this.render();
  }

  selectActiveMenuItem(menuItemId) {
    this.activeMenuItem = this.menu.find(menuItem => menuItem.id === menuItemId);
  }

  clearActiveMenuItem() {
    this.activeMenuItem = null;
  }

  get currentSubmenuItem() {
    return this.menu
      .filter(menuItem => menuItem.id !== 'featured_new_main')
      .flatMap(menuItem => menuItem.submenu)
      .find(submenuItem =>
        submenuItem.active_matcher && !!this.currentUrl.match(new RegExp(submenuItem.active_matcher))
      );
  }

  getSubmenuByGroups(menuItem) {
    if (!menuItem) {
      return [];
    }

    const submenuItems = menuItem.submenu;
    const submenuItemGroupNames = submenuItems.map(item => item.group);
    const groups = [...new Set(submenuItemGroupNames)].map(groupName => ({ name: groupName, items: [] }));

    for (const item of submenuItems) {
      groups.find(group => group.name === item.group).items.push(item);
    }

    return groups;
  }
}

export default ENavigationState;
