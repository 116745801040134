import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import { ENavigationProfileMenuState } from './state';
import { ENavigationProfileMenuEvents } from './events';
import { ENavigationProfileMenuTemplate } from './template';

export class ENavigationProfileMenu extends HTMLCustomElement {
  init() {
    this.refs = {};
    this.state = new ENavigationProfileMenuState(this);
    this.events = new ENavigationProfileMenuEvents(this);
    this.template = new ENavigationProfileMenuTemplate(this);
  }

  connectedCallback() {
    this.classList.add('e-navigation-profile-menu');

    this.render();

    window.addEventListener('message', this.events.onShareAccountSettingsChange);
  }

  disconnectedCallback() {
    window.removeEventListener('message', this.events.onShareAccountSettingsChange);
  }

  // Attributes
  static get observedAttributes() {
    return [
      'session-id', 'menu', 'customer-name', 'customer-id', 'admin-name', 'timezone',
      'timezone-offset'
    ];
  }

  set sessionId(value) {
    this.state.sessionId = value;
  }

  set menu(value) {
    const jsonValue = typeof value === 'string' ? value : JSON.stringify(value);
    this.state.menu = JSON.parse(jsonValue);
  }

  set customerName(value) {
    this.state.customerName = value;
  }

  set customerId(value) {
    this.state.customerId = value;
  }

  set adminName(value) {
    this.state.adminName = value;
  }

  set timezone(value) {
    this.state.timezone = value;
  }

  set timezoneOffset(value) {
    this.state.timezoneOffset = value;
  }

  // Rendering
  render() {
    render(this, this.template.createElement());
  }
}

export default ENavigationProfileMenu;
