import { render, html } from 'uhtml';

class Template {
  constructor(node) {
    this._node = node;
  }

  render(state) {
    render(this._node, html`<e-time datetime=${state.datetimeString} format="ymdhi"></e-date>`);
  }
}

export default Template;
