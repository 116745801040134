import autoBind from 'auto-bind';

export class ENavigationEvents {
  constructor(component) {
    autoBind(this);
    this.component = component;
    this.refs = component.refs;
    this.state = component.state;

    this._mainMenuOpenTimeout = null;
  }

  onMainMenuItemClick(event) {
    const menuItemId = event.currentTarget.getAttribute('menu-item-id');

    this.state.isMenuOpen = true;
    this.state.selectActiveMenuItem(menuItemId);

    this.onMenuItemClick(event);
  }

  onMainMenuFocusWithin() {
    this.state.isMenuOpen = true;
  }

  onMainMenuFocusOutside() {
    this.state.isMenuOpen = false;
  }

  onMainMenuMouseEnter() {
    this._mainMenuOpenTimeout = setTimeout(() => {
      this.state.isMenuOpen = true;
    }, this.state.openDelay);
  }

  onMainMenuMouseLeave() {
    if (this.refs.mainMenu.contains(document.activeElement)) { return; }

    this.state.isMenuOpen = false;

    if (!this._mainMenuOpenTimeout) { return; }

    clearTimeout(this._mainMenuOpenTimeout);
    this._mainMenuOpenTimeout = null;
  }

  onMenuItemClick(event) {
    const trackingId = event.currentTarget.getAttribute('tracking-id');
    const trackingAction = event.currentTarget.getAttribute('tracking-action');

    window.e.utils.v2.trace.trackEvent({
      category: 'Navigation',
      action: trackingAction,
      label: trackingId
    });

    this.component.dispatchEvent(new CustomEvent('trigger', {
      detail: {
        element: event.currentTarget,
        id: event.currentTarget.getAttribute('menu-item-id')
      }
    }));
  }

  onMenuEscape(event) {
    if (event.code !== 'Escape') { return; }

    event.stopPropagation();

    if (!this.state.activeMenuItem) { return; }

    const activeMenuItemElement = document.querySelector(`[menu-item-id=${this.state.activeMenuItem.id}]`);

    activeMenuItemElement.focus();
    this.state.clearActiveMenuItem();
  }
}

export default ENavigationEvents;
