import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import { ENavigationSearchFormState } from './state';
import { ENavigationSearchFormEvents } from './events';
import { ENavigationSearchFormTemplate } from './template';

export class ENavigationSearchForm extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = {};
    this.state = new ENavigationSearchFormState(this);
    this.events = new ENavigationSearchFormEvents(this);
    this.template = new ENavigationSearchFormTemplate(this);
  }

  connectedCallback() {
    this.render();
  }

  // Attributes
  static get observedAttributes() {
    return ['session-id', 'splitted-quick-search', 'charset'];
  }

  get sessionId() {
    return this.state.sessionId;
  }

  set sessionId(value) {
    this.state.sessionId = value;
  }

  get splittedQuickSearch() {
    return this.state.splittedQuickSearch;
  }

  set splittedQuickSearch(value) {
    this.state.splittedQuickSearch = super._convertAttributeToBoolean(value);

    if (this.state.splittedQuickSearch) {
      this.state.searchOption = 'contactByEmail';
    } else {
      this.state.searchOption = 'contact';
    }
  }

  get charset() {
    return this.state.charset;
  }

  set charset(value) {
    this.state.charset = value;
  }

  // Rendering
  render() {
    render(this, this.template.createSearchForm());
  }
}

export default ENavigationSearchForm;
