export class ENavigationProfileButtonState {
  constructor(component) {
    this.render = component.render.bind(component);

    this._menu = [];
    this._sessionId = '';
    this._customerName = '';
    this._customerId = '';
    this._adminName = '';
    this._timezone = '';
    this._timezoneOffset = 0;
    this._menuIsOpen = false;
  }

  get menu() {
    return this._menu;
  }

  set menu(value) {
    this._menu = value;
    this.render();
  }

  get menuIsOpen() {
    return this._menuIsOpen;
  }

  set menuIsOpen(value) {
    this._menuIsOpen = value;
    this.render();
  }

  get sessionId() {
    return this._sessionId;
  }

  set sessionId(value) {
    this._sessionId = value;
    this.render();
  }

  get customerName() {
    return this._customerName;
  }

  set customerName(value) {
    this._customerName = value;
    this.render();
  }

  get customerId() {
    return this._customerId;
  }

  set customerId(value) {
    this._customerId = value;
    this.render();
  }

  get adminName() {
    return this._adminName;
  }

  set adminName(value) {
    this._adminName = value;
    this.render();
  }

  get timezone() {
    return this._timezone;
  }

  set timezone(value) {
    this._timezone = value;
    this.render();
  }

  get timezoneOffset() {
    return this._timezoneOffset;
  }

  set timezoneOffset(value) {
    this._timezoneOffset = value;
    this.render();
  }
}

export default ENavigationProfileButtonState;
