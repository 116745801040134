import { config } from './config';

export class ENavigationSearchFormState {
  constructor(component) {
    this.render = component.render.bind(component);

    this._sessionId = null;
    this._charset = 'utf-8';
    this._splittedQuickSearch = false;
    this._searchKeyword = '';
    this._searchOption = 'contact';
  }

  get sessionId() {
    return this._sessionId;
  }

  set sessionId(value) {
    this._sessionId = value;
    this.render();
  }

  get charset() {
    return this._charset;
  }

  set charset(value) {
    this._charset = value;
    this.render();
  }

  get splittedQuickSearch() {
    return this._splittedQuickSearch;
  }

  set splittedQuickSearch(value) {
    this._splittedQuickSearch = value;
    this.render();
  }

  get searchKeyword() {
    return this._searchKeyword;
  }

  set searchKeyword(value) {
    this._searchKeyword = value;
    this.render();
  }

  get searchOption() {
    return this._searchOption;
  }

  set searchOption(value) {
    this._searchOption = value;
    this.render();
  }

  get url() {
    if (!this.searchOption || !this.searchKeyword || !config[this.searchOption]) { return null; }

    return config[this.searchOption].getUrl({
      needle: this.charset === 'utf-8' ? encodeURIComponent(this.searchKeyword) : this.searchKeyword,
      sessionId: this.sessionId
    });
  }

  get placeholder() {
    return this.searchOption ? config[this.searchOption].placeholder : '';
  }
}

export default ENavigationSearchFormState;
